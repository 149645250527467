import EZUIKit from 'ezuikit-js';

const ezopenBaseUrl = "ezopen://open.ys7.com";

let player = null;
let initedPlayers = {};

export function initDisplay(domContainer, accessToken, serialNum, option = {}) {
    let _player = new EZUIKit.EZUIKitPlayer({
        autoplay: false,
        audio: 1,
        id: domContainer,
        accessToken: accessToken,
        url: generateLiveUrl(serialNum, option),
        template: 'standard',
        plugin: option['talk'] || ['talk'],
        header: option['header'],
        footer: option['footer'],
        handleSuccess: data => {
            console.log("播放成功", data)
            setTimeout(function() {
                let _p = pickPlayer(domContainer)
                _p.stop()
            }, 120000);
        },
        handleError: data => console.log("播放失败", data),
        openSoundCallBack: data => console.log("开启声音回调", data),
        closeSoundCallBack: data => console.log("关闭声音回调", data),
        startSaveCallBack: data => console.log("开始录像回调", data),
        stopSaveCallBack: data => console.log("录像回调", data),
        capturePictureCallBack: data => console.log("截图成功回调", data),
        fullScreenCallBack: data => console.log("全屏回调", data),
        // width: "100%",
        // height: "100%",
    })

    player = _player;
    initedPlayers[domContainer] = _player;

    return new Promise(function(resolve, reject) {
        if (player) {
            resolve(player)
        } else {
            reject("not found player instance")
        }
    })
}

export function generateLiveUrl(serialNum, option) {
    let url = `${ezopenBaseUrl}/${serialNum}/${option.channel || 1}.${option.quality != null ? option.quality + '.' : ''}${option.way || 'live'}`
    console.log("ezopen url = ", url)
    return url
}

export function stopVideo() {
    return player.stop().then((option) => {
        console.log("视频播放停止成功！", option)
    });
}

export function playVideo() {
    return player.play().then((option) => {
        console.log("视频播放成功", option)
    });
}

export function refreshVideo() {
    player.stop().then((option) => {
        console.log("视频播放停止成功！", option)
        player.play().then((option) => {
            console.log("视频播放成功", option)
        });
    });
}

export function changeVideoUrl(serialNum, option = {}) {
    player.stop().then(() => {
        let newUrl = generateLiveUrl(serialNum, option);
        player.play(newUrl).then((option) => {
            console.log("视频切换成功！", option);
            player.stop();
        });
    })
}

export function startTalk() {
    player.startTalk()
    console.log("打开对话成功！");
    return new Promise(function(resolve, reject) {
        if (player) {
            resolve()
        } else {
            reject("not found player instance")
        }
    })
}

export function stopTalk() {
    player.stopTalk()
    console.log("停止对话成功！");
    return new Promise(function(resolve, reject) {
        if (player) {
            resolve()
        } else {
            reject("not found player instance")
        }
    })
}

export function startRecord() {
    player.startSave();
}

export function stopRecord() {
    player.stopSave();
}

export function reSize(width = null, height = null) {
    player.reSize(width, height);
}

export function destroy() {
    player = null
    if (!player)
        console.log("player destoried")
}

export function checkPlayer() {
    return Boolean(player)
}

export function pickPlayer(domContainer) {
    player = initedPlayers[domContainer]
    return player
}

export function resetPlayer() {
    initedPlayers = {}
}

export default { initDisplay, playVideo, refreshVideo, stopVideo, changeVideoUrl, stopTalk, startTalk, checkPlayer, destroy, reSize, pickPlayer, startRecord, stopRecord, resetPlayer }